import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import classes from './LetsBuild.module.css';
import validator from 'validator';
import ErrorModal from '../ui/ErrorModal';


export default function LetsBuild() {
  const [currentEmail, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEmailWarning, setEmailWarningState] = useState(false);

  function onEmailChange(event) {
    setEmail(event.target.value);
  }

  function onSubmitHandler(event) {
    event.preventDefault();

    const userData = {
      email: currentEmail,
    };
    
    if (validator.isEmail(currentEmail)) {            
      fetch(
        'https://blare-website-default-rtdb.firebaseio.com/requests.json',
      ).then((response) => {
        return response.json();
      }).then((data) => {
        for (const key in data) {
          const user = 
          {
              id: key,
              ...data[key]
          };

          if (userData.email === user.email) {
              setEmailWarningState(true);
              setEmail('');
          }
          else {
            fetch(
              'https://blare-website-default-rtdb.firebaseio.com/requests.json',
              {
                  method: 'POST',
                  body: JSON.stringify(userData),
                  headers: {
                      'Content-Type': 'application/json'
                  }
              }
            );
          }
        }
      });
    } else {
      setShowModal(true);
    }
  }

  return (
    <React.Fragment>
      {showEmailWarning && ReactDOM.createPortal(
        <ErrorModal 
          title='We are excited too!' 
          message='We have already recieved a request for this email, and will be in touch with you shortly!' 
          onExit={setEmailWarningState}/>,
          document.getElementById('overlay-root')
      )}
      {showModal && ReactDOM.createPortal(
        <ErrorModal 
          title='Email cannot be found.' 
          message='Please make sure you entered a valid email.' 
          onExit={setShowModal}/>,
          document.getElementById('overlay-root')
      )}

      <div className={classes.container}>
        <header>Lets build something together...</header>
        <form className={classes.form} onSubmit={onSubmitHandler}>
          <input type="text" value={currentEmail} placeholder='Enter your email' required id="email" onChange={onEmailChange}/>
          <button type='submit'>Contact Us</button>
        </form>
      </div>
    </React.Fragment>
  );
}