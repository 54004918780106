import React from 'react';
import ReactDOM from 'react-dom';
import Button from './Button';

import classes from './ErrorModal.module.css';

export default function ErrorModal(props) {
  function onClickHandler() {
    props.onExit(false);
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(<div className={classes.backdrop} onClick={onClickHandler}/>, document.getElementById('backdrop-root'))}
      <div className={classes.modal}>
        <header className={classes.header}>
          <h2>{props.title}</h2>
        </header>
        <div className={classes.content}>
          <p>{props.message}</p>
        </div>
        <footer className={classes.actions}>
          <Button onClick={onClickHandler}>Okay</Button>
        </footer>
      </div>
    </React.Fragment>
  );
}