import classes from './App.module.css';
import LetsBuild from './components/cards/LetsBuild';
import background from './images/blueprints.jpg';

function App() {
  return (
    <div className={classes.App} style={{backgroundImage: `url(${background})` }}>
      <div className={classes.blurb}>
        <p>Blare Interactive is now Blare Commmerce</p>
      </div>
      <LetsBuild />
    </div>
  );
}

export default App;
